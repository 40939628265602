import { API } from "../config/AppConfig";
import { AUDIT_UPLOADS, RECENT_AUDITS } from "../constants/Endpoints";

class MyAuditRepository {

  async getAuditUploads(filterParams) {
    const response = await API.get(AUDIT_UPLOADS, {
      queryParams: filterParams
    });
    return response;
  }

  async getRecentAudits() {
    const response = await API.get(RECENT_AUDITS);
    return response;
  }

}

const myAuditRepository = new MyAuditRepository();
export default myAuditRepository;