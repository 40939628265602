export const validateColumnValue = (columnName, value) => {
    const patterns = {
      encounter_no: /^.{1,20}$/,
      icd: /^.{1,20}$/,
      modifier: /^.{1,20}$/,
      rvu: /^\d{1,10}(\.\d{1,4})?$/,
      dollar: /^\d{1,10}(\.\d{1,4})?$/,
      enc_dt: /^(?:\d{2}-\d{2}-\d{2}|\d{2}-\d{2}-\d{4})$/,
      // notes: /^.{0,800}$/,
    };
    const errorMessages = {
      encounter_no: `Invalid value for ${columnName}: Alphanumeric, max length 20`,
      icd: `Invalid value for ${columnName}: Alphanumeric, max length 20`,
      modifier: `Invalid value for ${columnName}: Alphanumeric, max length 20`,
      rvu: `Invalid value for ${columnName}: Numeric or float, max 10 digits, 4 decimals.`,
      dollar: `Invalid value for ${columnName}: Numeric or float, max 10 digits, 4 decimals.`,
      missing_info: `Invalid value for ${columnName}.`,
      enc_dt: `Invalid value for ${columnName}: Use MM-DD-YY/YYYY format`,
      // notes: `Invalid value for ${columnName}: Alphanumeric, max length 800`,
    };
    
    if (columnName === 'encounter_no') {
      return {
        isValid: patterns.encounter_no.test(value),
        message: errorMessages.encounter_no,
      };
    }
    if (/(.*)_icd(.*)/.test(columnName)) {
      return {
        isValid: patterns.icd.test(value),
        message: errorMessages.icd,
      };
    }
    if (/(.*)_modifier(.*)/.test(columnName)) {
      return {
        isValid: patterns.modifier.test(value),
        message: errorMessages.modifier,
      };
    }
    if (/(.*)_dollar_value/.test(columnName)) {
      return {
        isValid: patterns.dollar.test(value),
        message: errorMessages.dollar,
      };
    }
    if (columnName === 'provider_rvu' || columnName === 'audited_rvu') {
      return {
        isValid: patterns.rvu.test(value),
        message: errorMessages.rvu,
      };
    }

    if (columnName === 'missing_info') {
      const isValid = value === true || value === null;
      return {
        isValid: isValid,
        message: isValid ? '' : errorMessages.missing_info,
      };
    }
    
    if (columnName === 'enc_dt') {
    return {
      isValid: patterns.enc_dt.test(value),
      message: errorMessages.enc_dt,
      };
    }

    // if (['response', 'notes', 'provider_education', 'number_complexity', 'amount_complexity', 'risk_complications'].includes(columnName)) {
    //   return {
    //     isValid: patterns.notes.test(value),
    //     message: errorMessages.notes,
    //   };
    // }
    return { isValid: true, message: '' };
  };