/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  HStack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AppColors from '../../../../constants/AppColors';
import {
  SortDownIcon,
  SortUpIcon,
  XIconGray,
} from '../../../../constants/IconData';
import { AuditSheetContext } from '../../../../providers/AuditSheetProvider';
import CommentList from './CommentList';
import CommentSaveButton from './CommentSaveButton';

function NotesActivityDrawer({ isOpen, onClose, onOpenNotes }) {
  const [notes, setNotes] = useState('');
  const auditSheetContext = useContext(AuditSheetContext);
  const {
    currentUpload,
    allComments,
    setAllComments,
    postComment,
    isPostCommentLoading,
    getAllComments,
    commentFilters,
    setCommentFilters,
    totalComments,
  } = auditSheetContext;

  const addNotes = async () => {
    const body = {
      parent: null,
      audit_sheet_columns: [],
      audit_sheet_rows: [],
      action: 'OPEN',
      comment: notes,
      notes: true,
      chart: currentUpload.id,
      tagged_user: [],
    };
    const response = await postComment(body);
    setAllComments([...allComments, { ...response }]);
    setNotes('');
  };

  const getComment = async () => {
    const res = await getAllComments();
    if (commentFilters.page > 1) {
      setAllComments([...allComments, ...res]);
    }
  };

  useEffect(() => {
    if (Object.keys(commentFilters).length) {
      getComment();
    }
  }, [commentFilters]);

  const drawerRef = useRef();

  const onScroll = () => {
    if (drawerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = drawerRef.current;

      if (Math.floor(scrollTop) + clientHeight >= scrollHeight) {
        if (Math.ceil(totalComments / 10) >= commentFilters.page + 1)
          setCommentFilters({
            ...commentFilters,
            page: commentFilters.page + 1,
          });
      }
    }
  };

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size={'sm'}>
        <DrawerContent>
          <DrawerHeader bg={AppColors.primary}>
            <HStack justifyContent={'space-between'}>
              <HStack>
                <XIconGray
                  onClick={onClose}
                  style={{ color: AppColors.white, cursor: 'pointer' }}
                />
                <Text fontWeight={900} paddingLeft={4} color={AppColors.white}>
                  Notes and Activity
                </Text>
              </HStack>
              <Button
                fontSize={'sm'}
                bg={'rgba(0,0,0,0.05)'}
                color={'white'}
                fontWeight="normal"
                _hover={{
                  transition: 'all .1s ease',
                  bg: 'rgba(0,0,0,0.05)',
                  color: AppColors.white,
                  outline: `2px solid ${AppColors.primary}`,
                }}
                _click={{
                  bg: 'rgba(0,0,0,0.05)',
                }}
                onClick={() =>
                  setCommentFilters({
                    ...commentFilters,
                    reverse: !commentFilters.reverse,
                    page: 1,
                  })
                }
              >
                Sort by{' '}
                {commentFilters.reverse ? <SortUpIcon /> : <SortDownIcon />}
              </Button>
            </HStack>
          </DrawerHeader>

          <DrawerBody ref={drawerRef} onScroll={onScroll}>
            <CommentList onOpenNotes={onOpenNotes} />
          </DrawerBody>

          <DrawerFooter>
            <Box width={'100%'}>
              <Textarea
                name="notes"
                value={notes}
                onChange={e => {
                  setNotes(e.target.value);
                }}
                placeholder="Add notes...."
              />
              {notes ? (
                <CommentSaveButton
                  onSave={addNotes}
                  onCancel={() => setNotes('')}
                  saveButtonText="Send"
                  isLoading={isPostCommentLoading}
                />
              ) : null}
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default NotesActivityDrawer;
