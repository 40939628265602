import { extendTheme } from '@chakra-ui/react';
import AppColors from './AppColors';

export const FONT_FAMILY = {
  baiJamurjee: 'Bai Jamjuree',
  ptSans: 'PT Sans',
};

const theme = extendTheme({
  colors: {
    brand: {
      100: '#8ee9e5',
      200: '#7be5e1',
      300: '#69e1dd',
      400: '#56ded8',
      500: AppColors.primary,
      600: '#3cc4bf',
      700: '#36aeaa',
      800: '#2f9994',
      900: '#28837f',
    },
    secondary: {
      500: AppColors.secondary,
    },
  },
  fonts: {
    heading: `'Bai Jamjuree', sans-serif`,
    body: `'PT Sans', sans-serif`,
  },
});

export default theme;
