export const QA = 'QA';
export const STATUS_FILTER = {
  onHold: 'ON HOLD',
  awaitingAudit: 'AWAITING AUDIT',
  inProgress: 'IN PROGRESS',
  inReview: 'IN REVIEW',
  awaitingReview: 'AWAITING REVIEW',
  archived: 'ARCHIVED',
  awaitingAssignment: 'AWAITING ASSIGNMENT',
  qaRebuttal: 'QA REBUTTAL',
  clientRebuttal: 'CLIENT REBUTTAL',
};
export const NEW_UPLOAD = ['AWAITING REVIEW'];
export const ARCHIVED = 'ARCHIVED';
export const REBBUTTAL = ['QA REBUTTAL', 'CLIENT REBUTTAL'];
export const MY_AUDITS = [
  'AWAITING REVIEW',
  'CLIENT REBUTTAL',
  'IN REVIEW',
  'ON HOLD',
];
export const SELF = 'self';
export const URGENT = [
  'AWAITING REVIEW',
  'CLIENT REBUTTAL',
  'IN REVIEW',
  'ON HOLD',
  'QA REBUTTAL',
];
export const getAuditStatus = props => {
  switch (props) {
    case 'ON HOLD':
      return 'On Hold';
    case 'AWAITING AUDIT':
      return 'Awaiting Audit';
    case 'IN PROGRESS':
      return 'In Progress';
    case 'IN REVIEW':
      return 'In Review';
    case 'AWAITING REVIEW':
      return 'Awaiting Review';
    case 'ARCHIVED':
      return 'Archived';
    case 'AWAITING ASSIGNMENT':
      return 'Awaiting Assignment';
    case 'QA REBUTTAL':
      return 'QA Rebuttal';
    case 'CLIENT REBUTTAL':
      return 'Client Rebuttal';
    default:
      return 'N/A';
  }
};
export const SIZE = 10;
export const AWAITING_AUDIT = 'AWAITING AUDIT';
export const MY_AUDITS_ORDERING = [
  'chart_id',
  'upload_date',
  'client',
  'specialty__name',
  'total_pages',
  'status',
  'updated_date',
  'auditor',
  'qa',
  'audited_date',
  '-chart_id',
  '-upload_date',
  '-client',
  '-specialty__name',
  '-total_pages',
  '-status',
  '-updated_date',
  '-auditor',
  '-qa',
  '-audited_date',
];
export const REQUIRED_FIELDS = [
  'encounter_no',
  'rendering',
  'enc_dt',

  'srvcs_no',

  'provider_rvu',
  'provider_dollar_value',

  'response',
  'agree',
  'disagree',

  'audited_cpt',
  
  'audited_rvu',
  'audited_dollar_value',
];
export const ROLES_VALUES = {
  QA: 'QA',
  AUDITOR: 'Auditor',
  MANAGER: 'Manager',
  CLIENT: 'Client',
};
