import { Box, HStack, Image, Skeleton, Text } from '@chakra-ui/react';
import React, { useContext } from 'react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { RecentAuditImage } from '../../../constants/ImageData';
import { MyAuditContext } from '../../../providers/MyAuditProvider';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/Routes';

const RecentAudits = props => {
  const navigate = useNavigate();

  const { recentAudits, isRecentAuditLoading } = useContext(MyAuditContext);

  if (isRecentAuditLoading) {
    return (
      <HStack mt={2} gap={4} overflow={'hidden'}>
        {[1, 2, 3, 4, 5].map(i => {
          return (
            <Box key={`${i}-loader`}>
              <Skeleton
                 minWidth={250}
                key={`recent-audit-image-${i}`}
                h={140}
                borderRadius="2xl"
              />
              <Skeleton
                mt={2}
                w={10}
                key={`recent-audit-id-${i}`}
                h={4}
                borderRadius="sm"
              />
            </Box>
          );
        })}
      </HStack>
    );
  }
  if (!recentAudits.length) {
    return (
      <Box
        height={'100%'}
        width={'100%'}
        display="flex"
        justifyContent="center"
        alignItems={'center'}
      >
        <Text
          fontSize={'md'}
          fontFamily={FONT_FAMILY.ptSans}
          fontWeight={'bold'}
        >
          No audits to show
        </Text>
      </Box>
    );
  }
  return (
    <HStack justifyContent={'flex-start'} gap={4} mt={2} overflowX="hidden">
      {recentAudits.map((chart, index) => {
        return (
          <Box key={`${index}-chart`}>
            <Image
              position={'relative'}
              src={chart?.recent_audit_snaps || RecentAuditImage}
              minWidth="250"
              height="133"
              cursor={'pointer'}
              onClick={() => {
                navigate(ROUTES.AUDIT_SHEET.replace(':uploadId', chart.id));
              }}
              borderRadius='10px'
              border='2px solid #fff'
              boxShadow={'0 0 10px 0 #e4e4e4'}
            />
            <Text
               mt={5}
               pb={3}
              fontFamily={FONT_FAMILY.baiJamurjee}
              color={AppColors.secondary}
              fontSize="18px"
            >
              {chart.chart_id}
            </Text>
          </Box>
        );
      })}
    </HStack>
  );
};

export default RecentAudits;
