/* eslint-disable react-hooks/exhaustive-deps */
import { Box, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  useCQMediaQuery
} from '@laxmimanogna/code-quick-components';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { MyAuditContext } from '../../../../providers/MyAuditProvider';
import { createColumnHelper } from '@tanstack/react-table';
import { UrgentIcon } from '../../../../constants/IconData';
import { format } from 'date-fns';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { toTitleCase } from '../../../../utils/common.utils';
import { renderActionComponent } from '../DashboardComponents';
import { useNavigate } from 'react-router-dom';
import {
  getAuditStatus,
  MY_AUDITS_ORDERING,
} from '../../../../constants/constants';

const columnHelper = createColumnHelper();

function MyAudits() {
  const navigate = useNavigate();
  const auditContext = useContext(MyAuditContext);
  const [ordering, setOrdering] = useState('');
  const { getMyAuditUpload } = auditContext;

  useEffect(() => {
    let searching = !!(auditContext.filterParams.searchText && auditContext.filterParams.searchText.trim());
    auditContext.getMyAuditUpload.loadAPI("Qa", "Audit Uploads", searching);
  }, [auditContext.filterParams, getMyAuditUpload.showOrdering]);

  useEffect(() => {
    if (MY_AUDITS_ORDERING.includes(ordering)) {
      getMyAuditUpload.setParams({ ordering });
    }
  }, [ordering]);
  let columns = useMemo(() => [

    columnHelper.accessor('id', {
      id: 'urgent_column',
      enableSorting: false,
      header: () => '',
      cell: info => {
        return (
          <HStack
            textAlign={'right'}
            justifyContent={'flex-end'}
            alignItems={'end'}
            // spacing={3}
          >
            {info.row.original?.urgent ? (
            <UrgentIcon style={{ width: 20, height: 8 }} />
            ) : (
              <Box ml={2} />
            )}
          </HStack>
        );
      },
    }),


    columnHelper.accessor('upload_id', {
      header: () => 'Upload #',
      id: 'chart_id',
      cell: info => {
        return info.renderValue()
      },
    }),

    columnHelper.accessor(row => row.uploaded_date, {
      id: 'upload_date',
      header: () => <Text ml={-4}>Chart Upload</Text>,
      cell: info => {
        const cellValue = info.getValue();
        if (!cellValue) {
          return 'N/A';
        }
        return format(new Date(cellValue), 'MM/dd/yyyy');
      },
    }),

    columnHelper.accessor('audited_date', {
      header: () => <Text ml={-4}>Audit Upload</Text>,
      id: 'audited_date',
      cell: info => {
        const auditUpload = info.getValue();
        if (!auditUpload) {
          return 'N/A';
        }
        return format(new Date(auditUpload), 'MM/dd/yyyy');
      },
    }),

    columnHelper.accessor('total_page', {
      header: () => 'Pages',
      id: 'total_pages',
      enableSorting: false,
      cell: info => {
        const Pages = info.row.original?.total_page;
        if (!Pages) {
          return '0';
        }
        return Pages;
      },
    }),

    columnHelper.accessor(p => p.specialties?.name, {
      header: () => 'Specialties',
      id: 'specialty__name',
      cell: info => {
        const specialties = info.row.original.specialties?.name;

        if (!specialties) {
          return 'N/A';
        }
        return specialties === 'ent' ? 'ENT' : toTitleCase(specialties);
      },
    }),

    columnHelper.accessor(p => p.assigned_auditor.first_name, {
      header: () => 'Auditor',
      id: 'auditor',
      cell: info => {
        const qa = info.row.original?.assigned_auditor;
        if (!qa) {
          return 'N/A';
        }
        return toTitleCase(`${qa?.first_name} ${qa?.last_name}`);
      },
    }),

    columnHelper.accessor('status', {
      header: () => 'Status',
      id: 'status',
      cell: info => {
        const status = info.row.original?.status;
        if (!status) {
          return 'N/A';
        }
        return getAuditStatus(status);
      },
    }),

    columnHelper.accessor('actions', {
      header: () => <Text ml={-10}>Actions</Text>,
      enableSorting: false,
      cell: info => {
        return renderActionComponent(info.row.original, navigate, auditContext);
      },
    }),
  ]);

  const canScroll = useCQMediaQuery({
    lg: false,
    md: false,
    sm: true,
  });

  function handlePreviousPage() {
    auditContext.getMyAuditUpload.loadPrevious("Qa", "Audit Uploads");
  }

  function handleNextPage() {
    auditContext.getMyAuditUpload.loadNext("Qa", "Audit Uploads");
  }

  function handleSizeChange(size) {
    auditContext.getMyAuditUpload.loadSize("Qa", "Audit Uploads", size );
  }

  function handlePageChangeByNumber(pageNumber) {
    auditContext.getMyAuditUpload.loadPageByNumber("Qa", "Audit Uploads", pageNumber);
  }


  return (
    <>
      <Box
        // pointerEvents={isUpdating ? 'none' : 'inherit'}
        overflowX={canScroll ? 'scroll' : 'inherit'}
      >
        <CQReactTable
          manualSortBy={true}
          isLoading={auditContext.isAuditUploadListLoading}
          data={auditContext.myAuditUploadList}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(auditContext.getMyAuditUpload)}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onPageChangeByNumber={handlePageChangeByNumber}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      </Box>
    </>
  );
}

export default MyAudits;
