import { Button, HStack } from "@chakra-ui/react"
import AppColors from "../../../../constants/AppColors"

const CommentSaveButton = ({ onCancel, onSave, saveButtonText, isLoading }) => {
    return <HStack mt={2}>
        <Button
            variant="solid"
            borderRadius={'3xl'}
            color={AppColors.white}
            paddingInline={'35px'}
            backgroundColor={AppColors.primary}
            _hover={{
                transition: 'all .1s ease',
                bg: AppColors.white,
                color: AppColors.primary,
                outline: `2px solid ${AppColors.primary}`,
              }}
            onClick={onSave}
            size='sm'
            fontSize={'xs'}
            fontWeight={'normal'}
            isLoading={isLoading}
        >
            {saveButtonText ? saveButtonText : 'Save'}
        </Button>
        <Button
            variant="outline"
            borderRadius={'3xl'}
            fontWeight={'normal'}
            paddingInline={'35px'}
            backgroundColor={AppColors.white}
            _hover={{
                transition: 'all .1s ease',
                bg: AppColors.secondary,
                color: AppColors.white,
                
              }}
            fontSize={'xs'}
            size='sm'
            borderWidth={2}
            borderColor={AppColors.secondary}
            onClick={onCancel}
        >
            Cancel
        </Button>
    </HStack>
}
export default CommentSaveButton