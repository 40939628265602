import { useBoolean } from '@chakra-ui/react';
import React from 'react';
import FileViewer from '../../../components/pdf_viewer/FileViewer';
import { EyeAvatarIcon } from '../../../constants/IconData';

function FileViewAction({ auditUpload }) {
  const [isFileOpen, ifoState] = useBoolean(false);

  return (
    <>
      <EyeAvatarIcon style={{ cursor: 'pointer' }} onClick={ifoState.on} />
      <FileViewer
        isOpen={isFileOpen}
        currentFile={auditUpload.file_obj}
        onClose={ifoState.off}
      />
    </>
  );
}

export default FileViewAction;
