import React, { useContext, useState, useEffect } from 'react';
import { Box, Text, GridItem, Button, HStack } from '@chakra-ui/react';
import {
  Heading,
  FormTextInput,
  FormPasswordInput,
  strengthChecker,
} from '@laxmimanogna/code-quick-components';
import {
  MailIcon,
  ViewIcon,
  EyeHiddenIcon,
  EyeIcon,
  LockIcon,
  DownIcon,
  UpIcon,
} from '../../constants/IconData';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/Routes';
import CQContainer from '../../components/layout/CQContainer';
import { AuthContext } from '../../providers/AuthProvider';
import { UserManagementContext } from '../../providers/UserManagementProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import PasswordStrength from './PasswordStrength';
import { toTitleCase } from '../../utils/common.utils';
import FormInputField from '../../components/form/form_text_input/FormTextInput';

const AccountForm = () => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const userManagementContext = useContext(UserManagementContext);

  const [password, setPassword] = useState(false);
  const [confirmPasswordStrength, setConfirmPasswordStrength] = useState(0);

  const currentUser = authContext.currentUser;

  const handleSaveData = async userformData => {
    let payload = {};
    let formdata = {
      first_name: userformData.first_name,
      last_name: userformData.last_name,
      new_password: userformData.new_password,
      current_password: userformData.current_password,
    };
    for (const prop in formdata) {
      if (formdata[prop]) {
        payload[prop] = formdata[prop];
      }
    }
    const res = await userManagementContext.updateUser(formdata);
    if (res.errors && Object.keys(res.errors).length) {
      Object.keys(res.errors).forEach(key => {
        if (key === 'new password') {
          setError('new_password', {
            type: 'custom',
            message: res.errors['new password'],
          });
        } else if (key === 'old password') {
          setError('current_password', {
            type: 'custom',
            message: res.errors['old password'],
          });
        } else {
          setError(key, {
            type: 'custom',
            message: res.errors[key],
          });
        }
      })
    }

  };
  const userUpdateSchema = yup.object({
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    password_flag: yup.bool(),
    current_password: yup.string().when('password_flag', {
      is: true,
      then: yup.string().required('Must enter current password'),
    }),
    new_password: yup.string().when('password_flag', {
      is: true,
      then: yup.string().required('Must enter new password'),
    }),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Confirm password must be same as new password')
      .when('password_flag', {
        is: true,
        then: yup.string().required('Must enter confirm password'),
      }),
  });

  const form = useForm({
    resolver: yupResolver(userUpdateSchema),
  });
  const { control, handleSubmit, reset, setValue, setError } = form;

  useEffect(() => {
    reset(currentUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return (
    <>
      <CQContainer ml={0} mr={0}>
        <form onSubmit={handleSubmit(handleSaveData)}>
          <HStack m={4}>
            <Box w="xs">
              <GridItem h={'55px'}>
                <FormInputField
                  name="first_name"
                  control={control}
                  id="first_name"
                  onChange={e => {
                    setValue('first_name', e.target.value);
                  }}
                />
              </GridItem>
            </Box>
            <Box w="xs">
              <GridItem h={'55px'}>
                <FormInputField
                  name="last_name"
                  control={control}
                  id="last_name"
                  onChange={e => {
                    setValue('last_name', e.target.value);
                  }}
                />
              </GridItem>
            </Box>
          </HStack>

          <HStack m={4}>
            <Box w="xs">
              <GridItem h={'30px'}>
                <FormTextInput
                  name="email"
                  control={control}
                  height={'50px'}
                  id="email"
                  disabled
                  leftIcon={<MailIcon style={{marginTop:'10px'}} />}
                  size="md"
                  onChange={e => {
                    setValue('last_name', e.target.value);
                  }}
                />
              </GridItem>
            </Box>
            <Box w="xs">
              <GridItem h={'30px'}>
                <FormTextInput
                  name="role"
                  control={control}
                  height={'50px'}
                  id="role"
                  disabled
                  leftIcon={<ViewIcon style={{marginTop:'10px'}}  />}
                  size="md"
                />
              </GridItem>
            </Box>
          </HStack>

          <HStack ml={5} alignItems={'center'}>
            <Text
              fontWeight="bold"
              fontSize="14px"
              style={{ fontFamily: FONT_FAMILY.ptSans }}
              cursor={'pointer'}
              onClick={() => {
                setPassword(!password)
                setValue('password_flag', !password)
              }}            >
              Change Password
            </Text>
            {!password ? (
              <DownIcon
                onClick={() => {
                  setPassword(!password)
                  setValue('password_flag', !password)
                }} style={{ width: 10, height: 10, cursor: 'pointer' }}
              />
            ) : (
              <UpIcon
                onClick={() => {
                  setPassword(!password)
                  setValue('password_flag', !password)
                }} style={{ width: 10, height: 10, cursor: 'pointer' }}
              />
            )}
          </HStack>

          {password && (
            <Box mt="5">
              <HStack m={4}>
                <Box w="xs">
                  <GridItem>
                    <FormPasswordInput
                      control={control}
                      name="current_password"
                      id="old_password"
                      placeholder="Current Password"
                      leftIcon={<LockIcon />}
                      passwordShowIcon={<EyeHiddenIcon />}
                      passwordHideIcon={<EyeIcon />}
                      size="md"
                    />
                  </GridItem>
                </Box>
              </HStack>
              <HStack m={4} alignItems={'start'}>
                <Box w="xs">
                  <FormPasswordInput
                    control={control}
                    name="new_password"
                    id="new_password"
                    placeholder="New Password"
                    leftIcon={<LockIcon />}
                    passwordShowIcon={<EyeHiddenIcon />}
                    passwordHideIcon={<EyeIcon />}
                    size="md"
                    onChange={e => {
                      const value = strengthChecker(e.target.value);
                      setConfirmPasswordStrength(value);
                    }}
                    mb={2}
                  />
                  <Text fontSize={'sm'} ml={1}>
                    Password Strength
                  </Text>
                  <PasswordStrength strength={confirmPasswordStrength} />
                </Box>
                <Box w="xs">
                  <FormPasswordInput
                    control={control}
                    name="confirm_password"
                    id="confirm_password"
                    placeholder="Confirm Password"
                    leftIcon={<LockIcon />}
                    passwordShowIcon={<EyeHiddenIcon />}
                    passwordHideIcon={<EyeIcon />}
                    size="md"
                   
                  />
                </Box>
              </HStack>
            </Box>
          )}
          <Box ml={5} mt={5}>
            <Heading
              as="h6"
              fontSize="14px"
              style={{ fontFamily: FONT_FAMILY.ptSans, fontWeight: 'bolder' }}
              mb={5}
            >
              Specialties
            </Heading>
            {currentUser.specialties && currentUser.specialties.length
              ? currentUser.specialties.map((specialist, i) => (
                <Button
                  key={i}
                  m={1}
                  borderRadius="3xl"
                  style={{ paddingInline: '30px' }}
                  _hover={{}}
                >
                  {specialist.name === 'ent'
                    ? 'ENT'
                    : toTitleCase(specialist.name)}
                </Button>
              ))
              : 'No Specialties'}
          </Box>
          <Box mt={12} ml={5}>
            <HStack>
              <Button
               mr={1}
               // variant="outline"
               color={AppColors.secondary}
               height={'50px'}
               _hover={{
                 transition: 'all .1s ease',
                 bgColor: AppColors.secondary,
                 color: AppColors.white,
                 // outline: ,
               }}
               style={{
                 paddingInline: '35px',
                 border:`2px solid ${AppColors.secondary}`
               }}
               // outline={`2px solid ${AppColors.secondary}`}
               bg={'#fff'}
               fontSize="sm"
               borderRadius="3xl"
                onClick={() => navigate(ROUTES.DASHBOARD)}
              >
                Cancel
              </Button>
              <Button
                borderRadius="3xl"
                color="white"
                outline={`2px`}
                height={'50px'}
                _hover={{
                  transition: 'all .1s ease',
                  bgColor: AppColors.white,
                  color: AppColors.primary,
                  outline: `2px solid ${AppColors.primary}`,
                }}

                fontSize="sm"
                bg={AppColors.primary}
                style={{ paddingInline: '35px' }}
                type="submit"
              >
                Save User
              </Button>
            </HStack>
          </Box>
        </form>
      </CQContainer>
    </>
  );
};
export default AccountForm;
