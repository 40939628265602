import {
  Box,
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  useDisclosure,
  HStack,
  Button,
  Tooltip,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {
  BackIcon,
  CodeQuickIcon,
  CommentIcon,
} from '../../../constants/IconData';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { format } from 'date-fns';
import AuditSheetFileViewer from './AuditSheetFileViewer';
import NotesDrawer from './NotesDrawer';

export default function Topbar({
  onAuditSubmit,
  updateAuditSheet,
  view,
  disableSubmit,
  lastSaved,
  onChange,
  onOpenNotes,
  onRebuttalClick,
}) {
  // context
  const auditSheetContext = useContext(AuditSheetContext);
  const { currentUpload } = auditSheetContext;
  // other hooks
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  async function onBack() {
    if (!view) {
      onChange(true);
      await updateAuditSheet();
      onChange(false);
    }
    navigate(-1);
  }

  return (
    <Box w={'100%'}>
      <Flex
        bg={AppColors.secondary}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        position="relative"
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} alignItems={'center'}>
          <CodeQuickIcon
            onClick={onBack}
            style={{
              height: 62,
              marginLeft: 10,
              padding: 5,
              cursor: 'pointer',
            }}
          />
          <BackIcon
            style={{
              marginLeft: 20,
              width: 40,
              height: 40,
              cursor: 'pointer',
              display: 'inline-block',
            }}
            onClick={onBack}
          />
          <Text
            display={{ base: 'none', md: 'block' }}
            color={'white'}
            fontSize={'xl'}
            fontWeight={'bold'}
            mx={7}
          >
            {currentUpload.upload_id}
          </Text>
        </Flex>

        <HStack alignItems={'center'} spacing={2} gap={2} mr={1}>
          <Text
            fontSize={'sm'}
            fontFamily={FONT_FAMILY.ptSans}
            color={'white'}
            fontWeight="bold"
          >
            {lastSaved
              ? ` Last saved ${format(
                  new Date(lastSaved),
                  'MMM dd, yyyy'
                )} at ${format(new Date(lastSaved), 'p')}`
              : null}
          </Text>
          <Tooltip hasArrow label="Mark as rebuttals" bg="white" color="black">
            <Box display="inline-block">
              <CommentIcon
                style={{ width: 40, height: 40, cursor: 'pointer' }}
                onClick={() => onRebuttalClick()}
              />
            </Box>
          </Tooltip>
          <AuditSheetFileViewer auditData={currentUpload} />
          <NotesDrawer onOpenNotes={onOpenNotes} />
          <Button
            mr={1}
            color={'white'}
            style={{
              paddingInline: '20px',
              outlineColor: AppColors.secondary,
              marginRight: '20px',
            }}
            borderRadius="3xl"
            bgColor={AppColors.yellowColor}
            fontSize={'sm'}
            onClick={onAuditSubmit}
            disabled={view || disableSubmit}
          >
            Submit Audit
          </Button>
        </HStack>
      </Flex>
    </Box>
  );
}
