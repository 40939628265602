/* eslint-disable react-hooks/exhaustive-deps */
import { Spinner } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import BlankLayout from '../components/layout/BlankLayout';
import PageLayout from '../components/layout/PageLayout';
import { withProvider } from '../hoc/withProvider';
import AuthProvider, { AuthContext } from '../providers/AuthProvider';

import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';

const MainRouter = props => {
  const authContext = useContext(AuthContext);
  const isAuthenticated = authContext.isAuthenticated;

  React.useEffect(() => {
    authContext.userLookup()
  }, [])


  const renderUnAuthenticatedRoute = () => {
    return (
      <Route path={'/'} element={<BlankLayout />}>
        {AuthRouter.map(r => {
          return (
            <Route key={`auth-${r.route}`} path={r.route} element={r.element} />
          );
        })}
      </Route>
    );
  }

  const renderAuthenticatedRoute = () => {
    return AppRouter.map(r => (
      <Route key={`app-${r.route}-container`} path={'/'} element={r.layout ? <r.layout /> : <PageLayout />}>
        <Route key={`app-${r.route}`} path={r.route} element={r.element} />
      </Route>
    ))
  }

  const renderRoutes = () => {
    if (isAuthenticated) {
      return renderAuthenticatedRoute()
    }
  }

  if (!authContext.isDetermined) {
    return <Spinner />
  }

  return (
    <Routes>
      {renderUnAuthenticatedRoute()}
      {renderRoutes()}

      {/* 404 page here */}
      <Route path={'*'} />
    </Routes>
  )
};

export default withProvider(AuthProvider, MainRouter);
