import { DYNAMIC_AUDIT_SHEET_KEY } from './constant';

export function prepareUpdatePayload(payload = []) {
  return payload.map(sheet => {
    const payloadData = sheet?.data?.map(entry => {
      const updatedEntry = { ...entry };
      const additionalAttributes = {};

      for (const key in updatedEntry) {
        if (!DYNAMIC_AUDIT_SHEET_KEY.includes(key)) {
          additionalAttributes[key] = updatedEntry[key];
          delete updatedEntry[key];
        } else if (key === 'audited_cpt') {
          updatedEntry.audited_code = updatedEntry.audited_cpt;
          delete updatedEntry.audited_cpt;
          if(updatedEntry.audited_code && updatedEntry.audited_code.split('*')[1]){
            additionalAttributes['audited_units'] = updatedEntry.audited_code.split('*')[1] || null;
            updatedEntry.audited_code = updatedEntry.audited_code.split('*')[0];
          }
        } else if (key === 'srvcs_no'){
          if(updatedEntry.srvcs_no && updatedEntry.srvcs_no.split('*')[1]){
            additionalAttributes['srvcs_units'] = updatedEntry.srvcs_no.split('*')[1] || null;
            updatedEntry.srvcs_no = updatedEntry.srvcs_no.split('*')[0];
          }
        }
      }

      if (Object.keys(additionalAttributes).length > 0) {
        updatedEntry.additional_attributes = additionalAttributes;
      }

      return updatedEntry;
    });

    return {
      ...sheet,
      data: addMissingKeysWithNull(payloadData),
    };
  });
}

function addMissingKeysWithNull(arr) {
  const dynamicKey = 'additional_attributes';

  const allKeys = new Set();
  const allNestedKeys = new Set();

  //  getting all keys
  arr?.forEach(obj => {
    Object.keys(obj).forEach(key => {
      allKeys.add(key);
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        Object.keys(obj[key]).forEach(nestedKey => {
          allNestedKeys.add(nestedKey);
        });
      }
    });
  });

  const dynamicKeyValues = new Map();
  // getting addionalattributes
  arr?.forEach(obj => {
    if (typeof obj[dynamicKey] === 'object' && obj[dynamicKey] !== null) {
      dynamicKeyValues.set(obj[dynamicKey], true);
    }
  });

  const unionKeys = new Set();

  arr?.forEach(obj => {
    if (dynamicKey in obj) {
      Object.keys(obj[dynamicKey]).forEach(key => {
        unionKeys.add(key);
      });
    }
  });

  arr?.forEach(obj => {
    if (dynamicKey in obj) {
      const dynamicKeyValue = { ...obj[dynamicKey] };
      unionKeys.forEach(nestedKey => {
        if (typeof dynamicKeyValue[nestedKey] === 'undefined') {
          dynamicKeyValue[nestedKey] = null;
        }
      });
      obj[dynamicKey] = dynamicKeyValue;
    } else {
      const nullObj = {};
      unionKeys.forEach(nestedKey => {
        nullObj[nestedKey] = null;
      });
      obj[dynamicKey] = nullObj;
    }

    allKeys?.forEach(key => {
      if (!(key in obj) || obj[key] === null) {
        obj[key] = null;
      }
    });

    dynamicKeyValues?.forEach((value, key) => {
      if (value && key !== obj[dynamicKey]) {
        Object.keys(key).forEach(k => {
          if (typeof obj[dynamicKey][k] === 'undefined') {
            obj[dynamicKey][k] = key[k];
          }
        });
      }
    });
  });

  return arr;
}

export function validateUnits(code_with_units){
  //Check if units available in CPT code or not, if not or available with correct formet return true else false
  if(code_with_units.includes('*')){
    const units = code_with_units.split('*')[1];
    const hasSingleAsterisk = code_with_units.split('*').length === 2;
    const isValid = /^\d+$/.test(units);

      return (isValid && hasSingleAsterisk);
    }
    return true;
}

export function handleAgreeDisagree(nextSheetData, changes){
  //For set icd agree and disagree value as per input
  let columnChangeName = changes[1]
  let autoChangeName = columnChangeName === 'icd_agree'? 'icd_disagree':'icd_agree'
  const row = {
    [columnChangeName]:
      changes[3] === ''
        ? null
        : changes[3]?.toLowerCase() === 'yes' ||
          changes[3]?.toLowerCase() === 'true'
        ? true
        : false,
    [autoChangeName]:
      changes[3] === ''
        ? null
        : changes[3]?.toLowerCase() === 'yes' ||
          changes[3]?.toLowerCase() === 'true'
        ? false
        : true,
  };
  return row;
}

export function updateICDAudit(nextSheetData, changes, row){
  if (row.icd_agree) {
    nextSheetData[changes[0]].audited_icd =
      nextSheetData[changes[0]].billed_icd;
  }else {
    nextSheetData[changes[0]].audited_icd = null;
  }
  return nextSheetData;
}