export const dynamicAuditColumnHeader = {
    missing_info: 'M.Info',
    encounter_no: 'Encounter #',
    rendering: 'Rendering',
    enc_dt: 'Enc Dt',
  
    srvcs_no: 'Billed CPT', // srvcs no
  
    billed_icd: 'Billed ICD',
  
    billed_modifier: 'Billed Modifier',
  
    provider_rvu: 'RVU',
    provider_dollar_value: '$ value',
    response: 'Response',
  
    agree: 'CPT Agree',
    disagree: 'CPT Disagree',

    icd_agree: 'ICD Agree',
    icd_disagree: 'ICD Disagree',
  
    audited_cpt: 'Audited CPT', // audited code
    audited_icd: 'Audited ICD',
  
    audited_modifier: 'Audited Modifier',
  
    audited_rvu: 'Audited RVU',
    audited_dollar_value: 'Audited $ Value',
    notes: 'Notes',
  
    // NEW COLUMNS
    provider_education: 'Provider Education',
    number_complexity: 'Number & Complexity',
    amount_complexity: 'Amount & Complexity',
    risk_complications: 'Mortality of PatientManagement',
  };

  export const columnType = {
    missing_info: 'checkbox',
    encounter_no: 'numeric',
    rendering: 'audit_sheet.dropdown_renderer',
    enc_dt: 'date',
  
    billed_icd: 'text',
    srvcs_no: 'text',
    billed_modifier: 'text',
  
    provider_rvu: 'text',
    provider_dollar_value: 'audit_sheet.currency_renderer',
  
    response: 'text',
    agree: 'text',
    disagree: 'text',
  
    // audited_code: 'text',
    audited_rvu: 'text',
  
    audited_dollar_value: 'text',
    notes: 'text',
  
    // NEW COLUMNS
    provider_education: 'text',
    number_complexity: 'text',
    amount_complexity: 'text',
    risk_complications: 'text',
  
    audited_cpt: 'text',
    audited_icd: 'text',
  
    audited_modifier: 'text',

    icd_agree: 'text',
    icd_disagree: 'text',
  };

  export const dynamicColumnRenderes = {
    missing_info: 'customStylesRendererCheckbox',
    encounter_no: 'customStylesRenderer',
    rendering: 'audit_sheet.dropdown_renderer',
    enc_dt: 'customStylesRenderer',
  
    srvcs_no: 'customStylesRenderer', // srvcs no
  
    billed_icd: 'cptIcdStylerRenders',
  
    billed_modifier: 'customStylesRenderer',
  
    provider_rvu: 'customStylesRenderer',
    provider_dollar_value: 'customStylesRenderer',
    response: 'audit_sheet.notes_renderer',
  
    agree: 'agreeRenderer',
    disagree: 'agreeRenderer',
  
    audited_cpt: 'customStylesRenderer', // audited code
  
    audited_icd: 'cptIcdStylerRenders',
  
    audited_modifier: 'customStylesRenderer',
  
    audited_rvu: 'customStylesRenderer',
    audited_dollar_value: 'customStylesRenderer',
    notes: 'audit_sheet.notes_renderer',
  
    // NEW COLUMNS
    provider_education: 'customStylesRenderer',
    number_complexity: 'customStylesRenderer',
    amount_complexity: 'customStylesRenderer',
    risk_complications: 'customStylesRenderer',

    icd_agree: 'agreeRenderer',
    icd_disagree: 'agreeRenderer',
  };

  export const AUDIT_COLUMN_HEADER = {
    MISSING_INFO: 'missing_info',
    ENCOUTER_NO: 'encounter_no',
    RENDERING: 'rendering',
    ENC_DT: 'enc_dt',
    SRVCS_NO: 'srvcs_no',
    PROVIDER_RUV: 'provider_rvu',
    PROVIDER_DOLLER_VALUE: 'provider_dollar_value',
    RESPONSE: 'response',
    AGREE: 'agree',
    DISAGREE: 'disagree',
    ICD_AGREE: 'icd_agree',
    ICD_DISAGREE: 'icd_disagree',
    AUDITED_CODE: 'audited_code',
    AUDITED_RUV: 'audited_rvu',
    AUDITOR_DOLLER_VALUE: 'audited_dollar_value',
    NOTES: 'notes',
    // NEW COLUMNS
    PROVIDER_EDUCATION: 'providerEducation',
    NUMBER_COMPLEXITY: 'numberAndComplexity',
    AMOUNT_COMPLEXITY: 'amountAndComplexity',
    MORTALITY_OF_PATIENT: 'mortalityOfPatientManagement',
  
    // adding dynmaically
    BILLED_CPT: 'billed_cpt',
    BILLED_MODIFIER: 'billed_modifier',
    AUDITED_ICD: 'audited_icd',
    AUDITED_CPT: 'audited_cpt',
    AUDITED_MODIFIER: 'audited_modifier',
  
    BILLED_ICD: 'billed_icd',
  };

  export const columnWidth = {
    missing_info: 0.03,
    encounter_no: 0.15,
    rendering: 0.15,
    enc_dt: 0.15,
  
    billed_icd: 0.15,
    srvcs_no: 0.15,
    billed_modifier: 0.15,
  
    provider_rvu: 0.15,
    provider_dollar_value: 0.15,
  
    response: 0.15,
    agree: 0.15,
    disagree: 0.15,

    icd_agree: 0.15,
    icd_disagree: 0.15,
  
    audited_code: 0.15,
  
    audited_rvu: 0.15,
  
    audited_dollar_value: 0.15,
    notes: 0.15,
  
    // NEW COLUMNS
    provider_education: 0.15,
    number_complexity: 0.15,
    amount_complexity: 0.15,
    risk_complications: 0.15,
  
    audited_icd: 0.15,
    audited_cpt: 0.15,
  
    audited_modifier: 0.15,
  };