import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  HStack,
  Center,
  Text,
  Circle,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { AuditSheetContext } from '../../../../providers/AuditSheetProvider';
import theme from '../../../../constants/Theme';

const DeleteCommentModal = props => {
  const [isDeleting, idState] = useBoolean(false);
  const auditSheetContext = useContext(AuditSheetContext);
  const { deleteComment } = auditSheetContext;

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        size={'lg'}
        onClose={props.onClose}
        isCentered
      >
        <ModalOverlay
          bg={AppColors.modalOverlayColor}
          style={{ opacity: 0.8 }}
        />
        <ModalContent p={15} alignItems={'center'}>
          <ModalHeader fontSize={'xl'} color={AppColors.secondary}>
            <Center
              textAlign={'center'}
              fontWeight="800"
              fontSize="xl"
              fontFamily={theme.fonts.heading}
              lineHeight="26px"
              letterSpacing={0}
            >
              Delete{' '}
              {!props.selectedComment?.audit_sheet_rows?.length
                ? 'Note'
                : 'Comment'}
            </Center>
          </ModalHeader>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-14}
            right={-10}
          >
            <Circle>
              <ModalCloseButton
                size={'md'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={props.onClose}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <ModalBody width={'70%'}>
            <Text
              textAlign={'center'}
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight="medium"
              fontSize={'lg'}
              color={AppColors.black}
            >
              Are you sure you want to delete this{' '}
              {!props.selectedComment?.audit_sheet_rows?.length
                ? 'note'
                : 'comment'}
              ?
            </Text>
            <HStack justifyContent="center" mt={5}>
              <Button
                 size={'sm'}
                 px={12}
                 rounded={'full'}
                 outline={`2px solid ${AppColors.secondary}`}
                 color={AppColors.secondary}
                 _hover={{
                   transition: 'all .1s ease',
                   bgColor: AppColors.secondary,
                   color: AppColors.white,
                 }}
                 variant={'outline'}
                 fontWeight={'normal'}
                onClick={props.onClose}
                
              >
                Cancel
              </Button>
              <Button
                size={'sm'}
                px={12}
                outline={`2px solid ${AppColors.red}`}
                color={AppColors.white}
                _hover={{
                  transition: 'all .1s ease',
                  bgColor: AppColors.white,
                  color: AppColors.red,
                }}
                rounded={'full'}
                textColor={'#fff'}
                bgColor={AppColors.red}
                fontWeight={'normal'}
                isLoading={isDeleting}
                onClick={async () => {
                  idState.on();
                  await deleteComment(
                    props.selectedComment.id,
                    props.selectedParentComment?.id,
                    !props.selectedComment.audit_sheet_columns.length
                  );
                  idState.off();
                  props.onClose();
                }}
              >
                Yes, Delete
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default DeleteCommentModal;
