import {
    Stack,
    Image,
    Box,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { QWatermark } from '../../../constants/ImageData';
import { renderCoverImage } from '../../../components/AuthCoverImage';
import ROUTES from '../../../constants/Routes';
import { useNavigate } from 'react-router-dom';
import { MarkIcon } from '../../../constants/IconData';
import { CQResetPasswordMessage } from '@laxmimanogna/code-quick-components';

export default function ResetConfirmationContainer() {
    const navigate = useNavigate();

    return (
        <Box bgColor={AppColors.primary} py={'5%'} px={'20%'} minH={'100vh'}>
            <Image top={'5vh'} left={30} style={{ height: '95vh' }} position={'absolute'} src={QWatermark} alt='q-watermark' />
            <Stack spacing={-8} direction={{ base: 'column', md: 'row' }}>
                {renderCoverImage()}
                <CQResetPasswordMessage
                    icon={<MarkIcon />}
                    buttonName='Login'
                    buttonOnClick={() => {
                        navigate(ROUTES.LOGIN_SCREEN);
                    }}
                    message='Successful password reset!'
                    buttonProps={{
                        mt: 5
                    }}
                />
            </Stack>
        </Box>
    );
}