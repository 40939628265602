import { HStack, Text } from '@chakra-ui/react';
import { PrimaryButton } from '@laxmimanogna/code-quick-components';
import React from 'react';
import AppColors from '../../../constants/AppColors';
import { DownIcon, SortIcon, UpIcon } from '../../../constants/IconData';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import FileViewAction from './FileViewAction';

const renderActionComponent = (auditUpload, navigate, myAuditContext) => {
  const buttonObj = getRowObject(auditUpload.status);
  return (
    <HStack justifyContent={'center'}>
      <PrimaryButton
        outline={`2px`}
        _hover={{
          transition: 'all .3s ease',
          bgColor: AppColors.white,
          color: auditUpload.urgent ? AppColors.danger : buttonObj.color,
          outline: `2px solid ${
            auditUpload.urgent ? AppColors.danger : buttonObj.color
          }`,
        }}
        bg={auditUpload.urgent ? AppColors.danger : buttonObj.color}
        fontSize={'14px'}
        fontWeight="normal"
        size="md"
        borderRadius="3xl"
        width={'130px'}
        onClick={() => {
          if (buttonObj.data === 'View') {
            navigate(
              ROUTES.VIEW_AUDIT_SHEET.replace(':uploadId', auditUpload.id)
            );
          } else {
            //call audit hour check
            myAuditContext.monitorAuditHour(auditUpload.id);
            navigate(ROUTES.AUDIT_SHEET.replace(':uploadId', auditUpload.id));
          }
        }}
      >
        {buttonObj.data}
      </PrimaryButton>
      <FileViewAction auditUpload={auditUpload} />
    </HStack>
  );
};

const renderHeader = (header, accessor, sorting, order) => {
  return (
    <HStack
      display={'flex'}
      alignItems="center"
      justifyContent={'center'}
      gap={0}
      onClick={() => {
        sorting(accessor);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Text
        style={{
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: FONT_FAMILY.ptSans,
          marginRight: 1,
        }}
      >
        {header}
      </Text>
      {accessor ? (
        order === accessor ? (
          <UpIcon
            style={{
              width: 10,
              height: 10,
              marginLeft: 1,
            }}
          />
        ) : order === `-${accessor}` ? (
          <DownIcon
            style={{
              width: 10,
              height: 10,
              marginLeft: 1,
            }}
          />
        ) : (
          <SortIcon
            style={{
              width: 15,
              height: 15,
              marginLeft: 1,
            }}
          />
        )
      ) : null}
    </HStack>
  );
};

const getRowObject = status => {
  let buttonObj;
  switch (status) {
    case 'AWAITING REVIEW':
      buttonObj = {
        data: 'Begin Review',
        color: AppColors.primary,
        bgColor: 'white',
      };
      break;
    case 'AWAITING AUDIT':
      buttonObj = {
        data: 'Begin Review',
        color: AppColors.primary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'ON HOLD':
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'IN PROGRESS':
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'IN REVIEW':
      buttonObj = {
        data: 'Review',
        color: AppColors.secondary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'ARCHIVED':
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'AWAITING ASSIGNMENT':
      buttonObj = {
        data: 'Begin Review',
        color: AppColors.primary,
        bgColor: AppColors.loginFormBg,
      };
      break;
    case 'QA REBUTTAL':
      buttonObj = {
        data: 'Review',
        color: AppColors.danger,
        bgColor: AppColors.lightDanger,
      };
      break;
    case 'CLIENT REBUTTAL':
      buttonObj = {
        data: 'Begin Review',
        color: AppColors.danger,
        bgColor: AppColors.lightDanger,
      };
      break;
    default:
      buttonObj = {
        data: 'View',
        color: AppColors.secondary,
        bgColor: 'white',
      };
  }
  return buttonObj;
};

export { renderActionComponent, renderHeader, getRowObject };
