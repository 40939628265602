/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Center,
  Circle,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  QAIcon,
  HelpIcon,
  LogoutIcon,
  UserIcon,
  WhiteBellIcon,
} from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import { AuthContext } from '../../providers/AuthProvider';
import React, { useContext, useEffect } from 'react';
import { FONT_FAMILY } from '../../constants/Theme';
import ROUTES from '../../constants/Routes';
import { useNavigate } from 'react-router-dom';
import { replaceRoute, toTitleCase } from '../../utils/common.utils';
import { withProvider } from '../../hoc/withProvider';
import NotificationProvider, {
  NotificationsContext,
} from '../../providers/NotificationProvider';
import { intlFormatDistance } from 'date-fns';
import { useInterval } from '../../hooks/useInterval';

function CQTopbar() {
  const navigate = useNavigate();

  const { isOpen, onToggle } = useDisclosure();
  const authContext = useContext(AuthContext);

  const myAccountRoute = () => {
    navigate(ROUTES.ACCOUNT);
  };

  const notificationContext = useContext(NotificationsContext);
  const {
    notifications,
    unReadNotification,
    setNotifications,
    setUnReadNotification,
    setNotificationCount,
  } = notificationContext;

  const initialNotificationData = React.useRef(null);
  const [notificationData, setNotificationData] = React.useState({});
  const [isHaveNewNotification, setIsHaveNewNotification] = React.useState({});
  const [newNotifications, setNewNotification] = React.useState(notifications);
  useEffect(() => {
    notificationContext.getNotifications(1);
    initialNotificationData.current = true;
  }, []);

  const handleNotificationStatus = async n => {
    const auditSheetRoute = replaceRoute(ROUTES.VIEW_AUDIT_SHEET, {
      uploadId: n.reference_chart,
    });
    if (!n.read)
      await notificationContext.changeNotificationStatus(
        n.notification_user_id
      );
    if (n?.reference_chart) navigate(auditSheetRoute);
  };

  useEffect(() => {
    setNewNotification(notifications);
  }, [notifications]);
  const loadNotification = async () => {
    const response = await notificationContext.loadNewNotification();
    const sortedNotifications = response.results?.sort(
      (rn, pn) => Number(rn.read) - Number(pn.read)
    );
    if (initialNotificationData.current) {
      setNotificationData({ ...response, results: sortedNotifications });
      initialNotificationData.current = null;
    } else {
      setIsHaveNewNotification({ ...response, results: sortedNotifications });
    }
  };
  useEffect(() => {
    const data = notificationData?.results?.filter(
      isNew =>
        !isHaveNewNotification?.results?.some(
          nd => nd.notification_id === isNew.notification_id
        )
    );
    if (data?.length) {
      setNewNotification(isHaveNewNotification?.results);
      setUnReadNotification(isHaveNewNotification?.unread_count);
      // setNotificationCount(isHaveNewNotification.count);
    }
  }, [isHaveNewNotification]);
  useInterval(loadNotification, 5000);

  return (
    <Box w={'100%'}>
      <Flex
        bg={'#fff'} 
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} ml={6} alignItems={'center'} height={'80px'}>
          <QAIcon
            onClick={() => navigate(ROUTES.DASHBOARD)}
            style={{ cursor: 'pointer', height: '3rem' }}
          />
            <Box
            ml={5}
            borderRadius={'md'}
            height={'60%'}
            opacity={0.5}
            border={'1px solid #fff '}
            mr={2}
          />
          <Text letterSpacing={"1px"} color={AppColors.secondary} fontWeight={"bold"} fontSize={"1.5rem"} fontFamily={FONT_FAMILY.baiJamurjee}>QA Portal</Text>
        </Flex>

        <HStack
          // flex={{ base: 1, md: 0 }}
          alignItems={'center'}
          spacing={2}
          mr={5}
        >
          <Menu autoSelect={false}>
            <MenuButton position={'relative'}>
              <WhiteBellIcon
                style={{
                  width: 40,
                  height: 40,
                  cursor: 'pointer',
                  display: 'inline-block',
                }}
              />
              {newNotifications?.length !== 0 && unReadNotification ? (
                <Circle
                  position={'absolute'}
                  top={0}
                  right={-2}
                  color={AppColors.white}
                  size="20px"
                  bg={AppColors.danger}
                  fontWeight="bold"
                  fontSize={'xs'}
                >
                  {unReadNotification}
                </Circle>
              ) : (
                ''
              )}
            </MenuButton>
            <MenuList shadow={'none'} border={'none'} bg={'transparent'}>
              {newNotifications?.length === 0 ? (
                <MenuItem
                  borderTopRadius={'md'}
                  bg={AppColors.white}
                  _hover={{ bg: AppColors.white }}
                >
                  <Box p={4}>No Notifications</Box>
                </MenuItem>
              ) : (
                newNotifications.slice(0, 3).map((n, i) => {
                  return (
                    <Box
                      key={`${i}-notification`}
                      onClick={() => {
                        handleNotificationStatus(n);
                      }}
                      cursor={'pointer'}
                      py={2}
                      borderTopRadius={i === 0 && 'md'}
                      bg={!n.read ? AppColors.white : '#EBEBEE'}
                      borderBottom={`1px solid ${AppColors.gray}`}
                    >
                      <MenuItem _hover={{ bg: 'none' }}>
                        <Text
                          fontWeight={'semibold'}
                          fontSize={'sm'}
                          color={AppColors.secondary}
                          px={2}
                        >
                          {n.message.startsWith('@') &&
                            toTitleCase(
                              `${n?.sender?.first_name} ${n?.sender?.last_name}`
                            )}
                        </Text>
                        <Text fontSize={'sm'} color={'black'}>
                          {n.message.startsWith('@')
                            ? n.message?.substring(1)
                            : n.message}
                        </Text>
                      </MenuItem>
                      <Text
                        color={AppColors.darkGray}
                        px={'25px'}
                        fontSize={'12px'}
                      >
                        {intlFormatDistance(
                          new Date(n.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </Text>
                    </Box>
                  );
                })
              )}

              <MenuItem
                _hover={{
                  bg: newNotifications?.length
                    ? AppColors.secondary
                    : AppColors.gray,
                }}
                bg={
                  newNotifications?.length
                    ? AppColors.secondary
                    : AppColors.gray
                }
                py={3}
                color={AppColors.white}
                borderBottomRadius={'md'}
                display={'flex'}
                justifyContent={'center'}
                onClick={() => {
                  if (newNotifications?.length !== 0)
                    navigate(ROUTES.NOTIFICATIONS);
                }}
              >
                <Center fontSize={'sm'}> View all</Center>
              </MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton px={4} py={2} transition="all 0.2s" borderRadius="md">
              <HStack alignItems={'center'}>
                <Text
                  fontWeight={'bold'}
                  color={AppColors.secondary}
                  fontSize={'18px'}
                  fontFamily={FONT_FAMILY.ptSans}
                >
                  {authContext?.currentUser?.email ?? 'N/A'}
                </Text>
                <ChevronDownIcon color={AppColors.secondary} />
              </HStack>
            </MenuButton>
            <MenuList padding={0}>
              <MenuItem
                onClick={() => myAccountRoute()}
                icon={<UserIcon style={{ width: 15, height: 15 }} />}
                height={'50px'}
                fontSize={'16px'}
              >
                {' '}
                My Account
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  navigate(ROUTES.HELP);
                }}
                icon={<HelpIcon style={{ width: 15, height: 15 }} />}
                height={'50px'}
                fontSize={'16px'}
              >
                Help
              </MenuItem> */}
              <MenuItem
                onClick={authContext.onLogout}
                icon={<LogoutIcon style={{ width: 15, height: 15 }} />}
                height={'50px'}
                fontSize={'16px'}
              >
                {' '}
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </Box>
  );
}

export default withProvider(NotificationProvider, CQTopbar);
