import {
  Circle,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import './modal.css';
import { CloseIcon, OpenIcon } from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import './pdfViewer.css';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const fileTypeMap = {
  'application/CDFV2': 'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'application/vnd.ms-excel',
  'application/zip': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const FileViewer = ({ isOpen, onClose, currentFile }) => {
  const fileName = currentFile?.name.split('_').join(' ');
  const renderFile = () => {
    const docs = [
      {
        uri: currentFile?.preview_url,
        fileType: fileTypeMap[currentFile?.mime_type] ?? currentFile?.mime_type,
        fileName: ' ',
      },
    ];

    return <DocViewer pluginRenderers={DocViewerRenderers} documents={docs} style={{ minHeight: 500 }} />;
  };

  return (
    <>
      <Modal
        size={'xl'}
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior={'outside'}
      >
        <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
        <ModalContent
          style={{ background: 'inherit', boxShadow: 'none', maxWidth: '50%' }}
        >
          <HStack mb={2}>
            <Text mt={2} color={'white'}>
              {fileName}
            </Text>
            <Spacer />
            {currentFile?.mime_type === 'application/pdf' ? <Circle
              size="30px"
              bg={AppColors.gray}
              color={AppColors.gray}
              onClick={() => {
                window.open(currentFile?.preview_url, '_blank');
              }}
              style={{ cursor: 'pointer' }}
            >
              <OpenIcon />
            </Circle> : null}
            <CloseIcon
              style={{ cursor: 'pointer', height: '30px', width: '30px' }}
              onClick={onClose}
            />
          </HStack>
          {renderFile()}
        </ModalContent>
      </Modal>
    </>
  );
};
export default FileViewer;
