import {
  Box,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import AppColors from '../../constants/AppColors';

export default function CQFooter() {

    const presentYear = new Date().getFullYear();

  return (
    <Box
      w={'100%'}
      py={4}
      alignItems={'center'}
      textAlign={'center'}
      // bg={"#EBEBEE"}
      // color={useColorModeValue('gray.700', 'gray.200')}>
      >
      <HStack w={'100%'} justifyContent={'center'} spacing={2} alignItems={'center'}>
        <Text>© Copyright @ {presentYear} </Text>
        <Text fontWeight={'bold'} color={AppColors.secondary}>RevIntegrity</Text>
      </HStack>
    </Box>
  );
}