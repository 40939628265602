import { API } from "../config/AppConfig";
import { USER_LOOKUP} from "../constants/Endpoints";

class UserManagementRepository {

  async updateUser(params) {
    const response = await API.put(USER_LOOKUP,{body: params});
    return response;
  }
}

const userManagementRepository = new UserManagementRepository();
export default userManagementRepository;