import AppColors from "../constants/AppColors";
import { STATUS_FILTER } from "../constants/constants";
import { DownIcon, LeftEnableIcon, LeftIcon, RightEnableIcon, RightIcon, SortIcon, UpIcon } from "../constants/IconData";
import { FONT_FAMILY } from "../constants/Theme";
import { getRowObject } from "../pages/dashboard/components/DashboardComponents";

export const defaultReactTableProp = {
  renderSortUpIcon,
  renderSortIcon,
  renderSortDownIcon,
  getCellProps,
  getHeaderCellProps,
  getRowProps,
  RightIcon: <RightIcon />,
  LeftIcon: <LeftIcon />,
}

export function paginationProps(pagination) {
    const canGoBack = pagination?.currentPage !== 1;
    const canGoNext = pagination?.totalPage !== pagination.currentPage;
  return {
    total: pagination.totalCount,
    pageCount: pagination.totalPage,
    size: pagination.showSize,
    currentPage: pagination.currentPage,
    LeftIcon: canGoBack ? <LeftEnableIcon /> : <LeftIcon />,
    RightIcon: canGoNext ? <RightEnableIcon /> : <RightIcon />
  };
}

export function renderSortUpIcon() {
  return <UpIcon
    style={{
      width: 10,
      height: 10,
      marginLeft: 1,
    }}
  />
}

export function renderSortDownIcon() {
  return <DownIcon
    style={{
      width: 10,
      height: 10,
      marginLeft: 1,
    }}
  />
}

export function renderSortIcon() {
  return <SortIcon
    style={{
      width: 15,
      height: 15,
      marginLeft: 1,
    }}
  />
}

export function getHeaderCellProps(header) {
  const startingHeaderId = ['assigned_auditor', 'name'];
  const tableHeader = [
    'client',
    'upload_date',
    'updated_date',
    'chart_id',
    'audited_date',
  ];
  return {
    textTransform: 'none',
    fontFamily: FONT_FAMILY.ptSans,
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'black',
    width: tableHeader.includes(header.id) ? '125%' : '100%',
    justifyContent: startingHeaderId.includes(header.id)
      ? 'flex-start'
      : 'center',
  };
}

export function getRowProps(row, options = { ignoreUrgent: false }) {
  const isUrgent = row.original?.urgent;
  const rowObject = getRowObject(row.original.status)
  const backgroundColor = isUrgent ? AppColors.lightDanger : rowObject.bgColor

  return {
    style: { backgroundColor }
  }
}

export function getCellProps(cell) {
  const status = cell.original?.status;
  let fontWeight = 'normal'

  if (status === STATUS_FILTER.awaitingReview || status === STATUS_FILTER.clientRebuttal || status === STATUS_FILTER.qaRebuttal) {
    fontWeight = 'bold'
  }
  const isUrgent = cell.original?.urgent;
  if (isUrgent) {
    fontWeight = 'bold'

  }
  const headerId = ['name'];
  const targetHeader = cell?.id.split('_')[1];
  return {
    style: {
      fontFamily: FONT_FAMILY.ptSans,
      color: AppColors.secondary,
      fontSize: '16px',
      fontWeight: fontWeight,
      textAlign: headerId.includes(targetHeader) ? 'left' : 'center',
    },
  }
}