import '@fontsource/bai-jamjuree';
import '@fontsource/pt-sans';

import React from 'react';
import {
  ChakraProvider,
} from '@chakra-ui/react';
import theme from './constants/Theme';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from './routers/MainRouter';

function CQQaPortalApp() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <MainRouter />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default CQQaPortalApp;
